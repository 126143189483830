<template>
  <div class="grid-container">
    <h1>{{ categoryView.name }}</h1>

    <div>
      <ul>
        <li v-for="category in categoryList"
        :key="category.id">
          <router-link
            :to="categoryUrl(category)"
            v-if="category.include_in_menu">
            {{ category.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <button class="button secondary hollow hide-for-large filters-toggle" data-toggle="offCanvasFilters">
          Filter
        </button>
        <off-canvas id="offCanvasFilters" in-canvas="large">
          <LayeredNav v-if="productList.aggregations" :aggregations="productList.aggregations" :active="activeFilters" />
        </off-canvas>
      </div>
      <div class="cell small-12 medium-9">
        <toolbar>
          <ListMode :list-mode="productListPage.list_mode" :current-layout="currentLayout" />
          <ShowingN v-if="productList.total_count" :total="productList.total_count" :page="currentPage" :per-page="perPage" />
          <Sorter :fields="productList.sort_fields" v-if="productList.sort_fields" :current="sort" />
        </toolbar>

        <List :mode="currentLayout" :products="productList.items" />

        <toolbar>
          <Pagination v-if="!this.$apollo.loading" :total-pages="productList.page_info.total_pages" :current-page="currentPage" />
          <PerPage v-if="perPageValues" :per-page-values="perPageValues" :current="perPage" />
        </toolbar>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";

.filters-toggle {
  width: 100%;
}
</style>

<script>
import _ from 'lodash';
import { PRODUCT_LIST } from "@/constants/graphql/catalog/products";
import { CATEGORY_LIST, CATEGORY_VIEW } from "@/constants/graphql/catalog/category";
import {PRODUCT_LIST_PAGE} from "@/constants/graphql/storeConfig";

import ListMode from "@/components/catalog/category/toolbar/ListMode";
import Pagination from "@/components/catalog/category/toolbar/Pagination";
import PerPage from "@/components/catalog/category/toolbar/PerPage";
import Toolbar from "@/components/catalog/category/Toolbar";
import ShowingN from "@/components/catalog/category/toolbar/ShowingN";
import Sorter from "@/components/catalog/category/toolbar/Sorter";
import List from "@/components/catalog/product/List";
import LayeredNav from "@/components/catalog/category/LayeredNav";
import OffCanvas from "@/components/foundation/OffCanvas";

export default {
  name: 'CategoryView',
  props: ['uid'],
  components: {
    OffCanvas,
    LayeredNav,
    List,
    Sorter,
    ShowingN,
    Toolbar,
    PerPage,
    Pagination,
    ListMode
  },
  data(){
    return {
      productList: {},
      categoryList: [],
      categoryView: {},
      productListPage: {}
    }
  },
  computed: {
    currentLayout: function() {
      let mode = this.productListPage.list_mode;

      switch (mode) {
        case "grid-list":
          mode = "grid";
          break;
        case "list-grid":
          mode = "list";
          break;
      }

      if (this.$route.query.list_mode) {
        if (this.$route.query.list_mode === mode) {
          this.removeQuery('list_mode');
        } else {
          mode = this.$route.query.list_mode;
        }
      }

      return mode;
    },
    perPage: function() {
      let per = this.productListPage[this.currentLayout + "_per_page"];

      if(this.$route.query.per_page) {
        if (parseInt(this.$route.query.per_page) === per) {
          this.removeQuery('per_page');
        } else {
          if (this.perPageValues.split(',').includes(this.$route.query.per_page)) {
            per = this.$route.query.per_page;
          } else {
            this.removeQuery('per_page');
          }
        }
      }

      return per
    },
    perPageValues: function() {
      return this.productListPage[this.currentLayout + "_per_page_values"];
    },
    currentPage: function() {
      let page = "1";

      if (this.$route.query.page) {
        if (this.$route.query.page === page) {
          this.removeQuery('page');
        } else {
          page = this.$route.query.page;
        }
      }

      return page;
    },
    sort: function(){
      if (_.has(this.productList, 'sort_fields.default')) {
        let order = "ASC";
        let sort_by = this.productList.sort_fields.default;

        if (this.$route.query.sort_order) {
          if (this.$route.query.sort_order === order) {
            this.removeQuery('sort_order');
          } else {
            order = this.$route.query.sort_order;
          }
        }

        if (this.$route.query.sort_by) {
          if (this.$route.query.sort_by === sort_by) {
            this.removeQuery('sort_by');
          } else {
            sort_by = this.$route.query.sort_by;
          }
        }

        let obj = {}
        obj[sort_by] = order;

        return obj;
      }

      return {};
    },
    activeFilters: function() {
      let filters = {...this.$route.query};
      let keys = [
          'page',
          'list_mode',
          'sort_by',
          'sort_order',
          'per_page'
      ];

      keys.forEach(function(key) {
        delete filters[key];
      });

      Object.keys(filters).forEach(function(key){
        if (key === "price") {
          filters[key] = { from: filters[key].split("_")[0], to: filters[key].split("_")[1] }
        } else {
          filters[key] = {in: filters[key].split(',')}
        }
      });

      return {
        ...filters,
        ...{
          category_uid: {eq: this.uid}
        }
      };
    }
  },
  methods: {
    categoryUrl(category) {
      return "/" + category.url_path + category.url_suffix;
    },
    removeQuery(key) {
      let query = Object.assign({}, this.$route.query);
      delete query[key];
      this.$router.replace({ query });
    }
  },
  apollo: {
    productListPage: {
      query: PRODUCT_LIST_PAGE
    },
    productList: {
      query: PRODUCT_LIST,
      variables() {
        return {
          filters: this.activeFilters,
          pageSize: this.perPage,
          currentPage: this.currentPage,
          sort: this.sort
        }
      }
    },
    categoryList: {
      query: CATEGORY_LIST,
      variables() {
        return {
          uid: this.uid
        }
      }
    },
    categoryView: {
      query: CATEGORY_VIEW,
      variables() {
        return {
          uid: this.uid
        }
      },
      update: data => data.categoryView.items[0]
    }
  }
}
</script>