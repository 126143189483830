<template>
  <div v-if="fields">
    <label class="sort-by">
      Sort by
      <select v-model="sortBy">
        <option v-for="option in fields.options" :value="option.value" :key="option.value">
          {{ option.label }}
        </option>
      </select>
    </label>

    <div class="sort-order">
      <label>
        <input type="radio" name="sort_order" value="ASC" v-model="sortOrder" :checked="(sortOrder === 'ASC')" />
        <span class="button secondary">
          <i class="fas fa-sort-down" aria-hidden="true"></i>
        </span>
      </label>

      <label>
        <input type="radio" name="sort_order" value="DESC" v-model="sortOrder" :checked="(sortOrder === 'DESC')" />
        <span class="button secondary">
          <i class="fas fa-sort-up" aria-hidden="true"></i>
        </span>
      </label>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";

.sort-by,
.sort-order {
  display: inline-block;
  vertical-align: middle;
}

.sort-by {
  select {
    width: auto;
  }
}

.sort-order {
  input {
    display: none;

    &:checked {
      + .button {
        display: none;
      }
    }
  }
}
</style>

<script>
import Vue from 'vue';

export default {
  name: 'Sorter',
  props: ['fields', 'current'],
  data() {
    return {
      sortBy: Vue.observable(Object.keys(this.current)[0]),
      sortOrder: Vue.observable(this.current[Object.keys(this.current)[0]])
    }
  },
  methods: {
    query(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      }
    },
    update() {
      this.$router.push({query: this.query({sort_by: this.sortBy, sort_order: this.sortOrder})});
    }
  },
  watch: {
    sortBy() {
      this.update()
    },
    sortOrder() {
      this.update()
    }
  }
}
</script>