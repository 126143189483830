<template>
  <ul>
    <li v-for="filter in activeFilters" :key="filter.attribute_code">
      {{filter.label}}:
      <span class="label" v-for="option in getChosenOption(filter.attribute_code)" :key="option.value" @click="remove(filter.attribute_code, option.value)">
        {{ option.label }}
      </span>
    </li>
  </ul>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Active',
  props: ['active', 'filters'],
  computed: {
    _active: function() {
      let filters = {...this.active};

      if (_.has(filters, 'category_uid')) {
        delete filters.category_uid;
      }

      if (_.has(filters, 'price')) {
        filters.price = {
          in: [
            filters.price.from + "_" + filters.price.to
          ]
        }
      }

      return filters;
    },
    activeFilters: function() {
      let self = this;
      let active = []

      Object.keys(this._active).forEach(function(key) {
        self.filters.forEach(function(filter) {
          if(filter.attribute_code === key) {
            active.push(filter);
          }
        });
      });

      return active;
    }
  },
  methods: {
    getChosenOption: function(attributeCode) {
      let self = this;
      let options = [];

      this.filters.forEach(function(filter) {
        if (filter.attribute_code === attributeCode) {
          filter.options.forEach(function(option) {
            if (self._active[attributeCode].in.includes(option.value)) {
              options.push(option);
            }
          });
        }
      });

      return options;
    },
    remove(attribute_code, value) {
      let filters = {...this.$route.query}
      filters[attribute_code] = filters[attribute_code].split(',').filter(i => i !== value).join(',');
      this.$router.push({ query: this.query(_.pickBy(filters)) });
    },
    query(newQuery) {
      let query = {...this.$route.query}
      let keys = [
        'page',
        'list_mode',
        'sort_by',
        'sort_order',
        'per_page'
      ];

      Object.keys(query).forEach(function(key) {
        if (!keys.includes(key)) {
          delete query[key];
        }
      });

      return {
        ...query,
        ...newQuery
      }
    }
  }
}
</script>