import gql from 'graphql-tag'

export const URL_RESOLVER = gql`
query urlResolver($url: String!) {
    urlResolver(url: $url) {
        entity_uid
        type
    }
}
`
