<template>
  <nav aria-label="Pagination" v-if="totalPages > 1">
    <ul class="pagination">
      <li class="pagination-previous" v-if="current !== 1">
        <router-link :to="{query: query({page: current - 1})}" aria-label="Previous page">
          Previous <span class="show-for-sr">page</span>
        </router-link>
      </li>

      <li v-for="n in totalPages" :key="n" :class="(n === current) ? 'current' : ''">
        <span class="show-for-sr" v-if="n === current">You're on page</span>
        <router-link :to="{query: query({page: n})}" v-if="n !== current" :aria-label="'Page ' + n">
          {{ n }}
        </router-link>
        <template v-if="n === current">
          {{ n }}
        </template>
      </li>

      <li class="pagination-next" v-if="current !== totalPages">
        <router-link :to="{query: query({page: current + 1})}" aria-label="Next page">
          Next <span class="show-for-sr">page</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['totalPages', 'currentPage'],
  computed: {
    current: function() {
      return parseInt(this.currentPage);
    }
  },
  methods: {
    query(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      }
    }
  }
}
</script>