<template>
  <div v-if="total !== 0">
    Items {{ range }} of {{ total }}
  </div>
</template>

<script>
export default {
  name: 'ShowingN',
  props: ['total', 'page', 'perPage'],
  computed: {
    start: function() {
      return 1 + ((parseInt(this.page) - 1) * parseInt(this.perPage));
    },
    end: function() {
      let n = parseInt(this.page) * parseInt(this.perPage);

      if (n > this.total) {
        return this.total;
      }

      return n;
    },
    range: function() {
      return this.start + " - " + this.end;
    }
  }
}
</script>