<template>
  <div>
    <router-link :to="url">
      <p class="h3" v-html="product.name"></p>
    </router-link>
    <Options v-if="product.configurable_options" :options="product.configurable_options" />
    <price :price-range="product.price_range" :special-price="product.special_price" />
    <span class="button">
      Add to basket
    </span>
  </div>
</template>

<script>
import Options from "@/components/catalog/product/Options";
import Price from "@/components/catalog/product/Price";

export default {
  name: 'Item',
  components: {Options, Price},
  props: ['product'],
  computed: {
    url: function() {
      return "/" + this.product.url_key + this.product.url_suffix;
    }
  }
}
</script>