<template>
  <div v-if="showSwitcher">
    <router-link :to="{query: query({list_mode: 'grid'})}">
      <span class="button secondary layout-switcher" :active="(currentLayout === 'grid')">
        <i class="fa fa-th fa-2x" aria-hidden="true"></i>
      </span>
    </router-link>

    <router-link :to="{query: query({list_mode: 'list'})}" >
      <span class="button secondary layout-switcher" :active="(currentLayout === 'list')">
        <i class="fa fa-th-list fa-2x" aria-hidden="true"></i>
      </span>
    </router-link>
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";

.button {
  &.layout-switcher {
    &[active] {
      background-color: darken(map-get($button-palette, secondary), 10%);
    }
  }
}
</style>

<script>
export default {
  name: 'ListMode',
  props: ['listMode', 'currentLayout'],
  computed: {
    showSwitcher: function() {
      let self = this;

      switch (this.listMode) {
        case "grid-list":
          self.selected = "grid";
          return true;
        case "list-grid":
          self.selected = "list";
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    query(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      }
    }
  }
}
</script>