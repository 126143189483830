<template>
  <div class="grid-x">
    <div v-for="product in products" :class="cellClass" :key="product.uid">
      <Item :product="product" />
    </div>
  </div>
</template>

<script>
import Item from "@/components/catalog/product/list/Item";

export default {
  name: 'List',
  components: {Item},
  props: ['mode', 'products'],
  computed: {
    cellClass: function() {
      switch (this.mode) {
        case "grid":
          return "cell small-12 medium-4 large-3";
        default:
          return "cell small-12";
      }
    }
  }
}
</script>