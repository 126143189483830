<template>
  <div v-if="!this.$apollo.loading" class="grid-container">
    <h1 v-html="productView.name"></h1>
    <p>{{ productView.sku }}</p>
    <Options v-if="productView.configurable_options" :options="productView.configurable_options" v-on:option-change="optionChange" />
    <price :price-range="productView.price_range" :special-price="productView.special_price" />
    <label>
      Qty
      <input type="number" ref="qty" v-model="qty" />
    </label>
    <button class="button" @click="addToCart" :disabled="!buttonStates[currentButtonState].enabled">
      {{ buttonStates[currentButtonState].text }}
    </button>
  </div>
</template>

<script>
import _ from "lodash";
import { URL_SUFFIX } from "@/constants/graphql/storeConfig";
import { PRODUCT_VIEW } from "@/constants/graphql/catalog/products";
import { ADD_PRODUCTS_TO_CART } from "@/constants/graphql/checkout/cart";

import Price from "@/components/catalog/product/Price";
import Options from "@/components/catalog/product/Options";

export default {
  name: "ProductView",
  components: {Price, Options},
  props: ['uid', 'route'],
  data(){
    return{
      productView: {},
      urlSuffix: {},
      defaultQty: 1,
      qty: 0,
      options: {},
      currentButtonState: "default",
      buttonStates: {
        default: {
          text: "Add to basket",
          enabled: true
        },
        adding: {
          text: "Adding",
          enabled: false
        },
        added: {
          text: "Added",
          enabled: false
        }
      }
    }
  },
  computed: {
    urlKey: function(){
      return this.route.replace(this.urlSuffix, '');
    }
  },
  methods: {
    addToCart() {
      this.currentButtonState = "adding";
      let requiredOptions = 0;

      if (_.has(this.productView, 'configurable_options')){
        requiredOptions = this.productView.configurable_options.length;
      }

      if (Object.keys(this.options).length === requiredOptions) {
        switch (this.productView.__typename) {
          case "SimpleProduct":
            this.callMutation({
              sku: this.productView.sku,
              quantity: this.qty
            });
            break;
          case "ConfigurableProduct":
            this.callMutation({
              sku: this.productView.sku,
              quantity: this.qty,
              selected_options: Object.keys(this.options).map(key => this.options[key])
            });
            break;
        }
      }
    },
    callMutation(item) {
      let self = this;

      this.$apollo.mutate({
        mutation: ADD_PRODUCTS_TO_CART,
        variables: {
          items: [item],
          cartId: localStorage.cart
        }
      }).then(function(data) {
        self.qty = self.defaultQty;
        self.$cart.cart = data.data.addProductsToCart.cart;
        self.currentButtonState = "added";

        setTimeout(function(){
          self.currentButtonState = "default";
        }, 1000)
      });
    },
    optionChange(data) {
      let code = Object.keys(data)[0]
      let value = data[code]

      if(value === "") return;

      this.options[code] = value;
    }
  },
  mounted() {
    this.qty = this.defaultQty;
  },
  apollo: {
    productView: {
      query: PRODUCT_VIEW,
      variables() {
        return {
          url_key: this.urlKey
        }
      },
      update: data => data.productView.items[0]
    },
    urlSuffix: {
      query: URL_SUFFIX,
      update: data => data.urlSuffix.product_url_suffix
    }
  }
}
</script>