<template>
  <ul class="accordion" data-accordion data-multi-expand="true" data-allow-all-closed="true">
    <Active :active="active" :filters="filters" />
    <template v-for="filter in filters">
    <li class="accordion-item" data-accordion-item :key="filter.attribute_code" v-if="!isActive(filter.attribute_code) && filter.attribute_code !== 'category_id'">
      <a href="#" class="accordion-title">{{ filter.label }}</a>

      <div class="accordion-content" data-tab-content>
        <ul>
            <li v-for="option in filter.options" :key="option.value" @click="applyFilter(filter.attribute_code, option.value)">
              {{ option.label }} ({{ option.count }})
            </li>
        </ul>
      </div>
    </li>
    </template>
  </ul>
</template>

<script>
import $ from 'jquery';
import Active from "@/components/catalog/category/layered-nav/Active";

export default {
  name: 'LayeredNav',
  components: {Active},
  props: ['aggregations', 'active'],
  computed: {
    filters: function(){
      return [...this.aggregations].sort( (a, b) => (a.label > b.label) ? 1 : -1 );
    }
  },
  methods: {
    query(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      }
    },
    applyFilter(code, value) {
      let filter = {};
      filter[code] = value;
      this.$router.push({query: this.query(filter)});
    },
    removeFilter(code) {
      let query = {...this.$router.query};
      delete query[code];
      this.$router.push({query: query});
    },
    isActive(code) {
      return Object.keys(this.active).includes(code);
    }
  },
  mounted() {
    $(document).foundation();
  }
}
</script>