<template>
  <div>
    <component v-bind:is="currentView" v-bind="currentViewProperties" />
  </div>
</template>

<script>
import { URL_RESOLVER } from "@/constants/graphql"
import CategoryView from "@/views/catalog/category/View"
import ProductView from "@/views/catalog/product/View"

export default {
  name: "Catchall",
  components: {
    CategoryView,
    ProductView
  },
  data() {
    return {
      urlResolver: {},
      route: this.$route.params.route
    }
  },
  computed: {
    currentView: function() {
      switch (this.urlResolver.type) {
        case "CATEGORY":
          return "CategoryView"
        case "PRODUCT":
          return "ProductView"
      }

      return "";
    },
    currentViewProperties: function() {
      return {
        uid: this.urlResolver.entity_uid,
        route: this.route
      }
    }
  },
  apollo: {
    urlResolver: {
      query: URL_RESOLVER,
      variables() {
        return {
          url: this.route
        }
      }
    }
  }
}
</script>