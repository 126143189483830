<template>
  <div class="per-page">
    Show
    <select v-model="selected" @change="onChange">
      <option v-for="value in values" :key="value">
        {{ value }}
      </option>
    </select>
    per page
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";

.per-page {
  select {
    display: inline;
    width: auto;
  }
}
</style>

<script>
export default {
  name: 'PerPage',
  props: ['perPageValues', 'current'],
  data() {
    return {
      selected: this.current
    }
  },
  computed: {
    values: function() {
      return this.perPageValues.split(',');
    }
  },
  methods: {
    query(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      }
    },
    onChange() {
      this.$router.push({query: this.query({per_page: this.selected})});
    }
  }
}
</script>