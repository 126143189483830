import gql from "graphql-tag";

export const PRODUCT_FRAGMENT = gql`
fragment ProductFragment on Products {
    items{
        __typename
        uid
        name
        sku
        url_key
        url_suffix
        price_range {
            minimum_price {
                regular_price {
                    currency
                    value
                }
            }
            maximum_price {
                regular_price {
                    currency
                    value
                }
            }
        }
        special_price
        ... on ConfigurableProduct {
            configurable_options {
                attribute_code
                label
                position
                values {
                    uid
                    store_label
                    swatch_data {
                        value
                        ... on ImageSwatchData {
                            thumbnail
                        }
                    }
                }
            }
        }
    }
}
`

export const PRODUCT_LIST = gql`
${PRODUCT_FRAGMENT}

query products(
    $filters: ProductAttributeFilterInput,
    $pageSize: Int,
    $currentPage: Int,
    $sort: ProductAttributeSortInput
){
    productList: products(
        filter: $filters,
        pageSize: $pageSize,
        currentPage: $currentPage,
        sort: $sort
    ){
        aggregations {
            attribute_code
            count
            label
            options {
                count
                label
                value
            }
        }
        page_info {
            total_pages
        }
        total_count
        sort_fields {
            default
            options {
                label
                value
            }
        }
        ... ProductFragment
    }
}
`

export const PRODUCT_VIEW = gql`
${PRODUCT_FRAGMENT}

query products($url_key: String!){
    productView: products(
        filter: {
            url_key: {eq: $url_key}
        }
    ){
        ... ProductFragment
    }
}
`
