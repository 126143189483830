<template>
  <div>
    <label :for="option.attribute_code">
      {{ option.label }}
      <select v-model="value" v-if="!option.values[0].swatch_data" :id="option.attribute_code">
        <option value="">Choose a {{ option.label }}</option>
        <option v-for="value in option.values" :key="value.uid" :value="value.uid">
          {{ value.store_label }}
        </option>
      </select>

      <div v-if="option.values[0].swatch_data" class="swatches">
        <label v-for="val in option.values" :key="val.uid">
          <input class="swatch" type="radio" v-model="value" :value="val.uid" style="display: none;">

          <span class="button" v-if="isHex(val.swatch_data.value)" :style="'background-color: ' + val.swatch_data.value + '; min-width: 40px;'">
            &nbsp;
          </span>

          <span class="button hollow" v-if="!isHex(val.swatch_data.value)">
            <img :src="val.swatch_data.thumbnail" :alt="val.store_label" v-if="val.swatch_data.thumbnail" />
            
            <span v-if="!val.swatch_data.thumbnail">
              {{ val.swatch_data.value }}
            </span>
          </span>
        </label>
      </div>
    </label>
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";

.swatches {
  display: flex;
  flex-wrap: wrap;

  label {
    margin-right: $global-margin;
  }

  input[type="radio"] {
    display: none;

    &:checked {
      + .button {
        border-color: $black;
        color: $black;
        font-weight: 700;
      }
    }
  }
}
</style>

<script>
import Vue from 'vue'

export default {
  name: 'Option',
  props: ['option'],
  data() {
    return {
      value: Vue.observable('')
    }
  },
  methods: {
    isHex(str) {
      let regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      return regex.exec(str);
    }
  },
  watch: {
    value() {
      let data = {};
      data[this.option.attribute_code] = this.value
      this.$emit("option-change", data)
    }
  }
}
</script>