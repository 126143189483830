<template>
  <div>
    <Option v-for="option in options" :option="option" :key="option.position" v-on:option-change="optionChange" />
  </div>
</template>

<script>
import Option from "@/components/catalog/product/options/Option";

export default {
  name: 'Options',
  components: {Option},
  props: ['options'],
  methods: {
    optionChange(data){
      this.$emit("option-change", data)
    }
  }
}
</script>